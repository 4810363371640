import React from "react";

import CustomCard from "../../components/CustomCard";
import { ReactComponent as LeiteCondensadoIcon } from './leite-condensado.svg';

export default function InsumoCard() {

    return (
        <CustomCard
            linkAction={'/insumos'}
            name={'Insumos'}
            description={'Clique aqui para gerenciar os insumos'}
            IconComponent={LeiteCondensadoIcon}
        />
    );
}