import {Box, Paper, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import { useSnackbar } from "notistack";
import {useNavigate} from "react-router-dom";
import {useCreateEmbalagemMutation} from "./embalagemSlice";
import EmbalagemForm from "./components/EmbalagemForm";
import {Embalagem} from "../../types/Embalagem";

export default function CreateEmbalagem() {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [isDisabled, setIsDisabled] = useState(false);

    const [createEmbalagem, status] = useCreateEmbalagemMutation();

    const [embalagemState, setEmbalagemState] = useState<Embalagem>({
        id: "",
        descricao: "",
        quantidade: {},
        custo: 0
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;

        if(name === 'quantidade') {
            setEmbalagemState(prevState => ({
                ...prevState,
                quantidade: {
                    ...prevState.quantidade,
                    valor: +value
                }
            }));
        } else {
            setEmbalagemState(insumoState => ({...insumoState, [name]: value}));
        }

    };

    const handleChangeOnEnum = (event: any, newValue: { label: string, option: string } | null) => {
        if (!newValue) {
            return;
        }
        setEmbalagemState(prevState => ({
            ...prevState,
            quantidade: {
                ...prevState.quantidade,
                unidade: newValue.option
            }
        }));
    };

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        await createEmbalagem(embalagemState);
    }

    useEffect(() => {
        if (status.isSuccess) {
            enqueueSnackbar("Embalagem cadastrada com successo", {variant: "success"});
            setIsDisabled(true);
            navigate(-1);
        }
        if (status.isError) {
            enqueueSnackbar("Não foi possível cadastrar a embalagem", {variant: "error"});
        }
    }, [enqueueSnackbar, status.isError, status.isSuccess, navigate]);

    return (
        <Box>
            <Paper>
                <Box p={2}>
                    <Box mb={2}>
                        <Typography variant='h4'>Nova embalagem</Typography>
                    </Box>
                </Box>

                <EmbalagemForm
                    embalagem={embalagemState}
                    isUpdating={status.isLoading}
                    isLoading={false}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    handleChangeOnEnum={handleChangeOnEnum}
                />
            </Paper>
        </Box>
    )
}
