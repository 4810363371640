import { createTheme } from "@mui/material";
import { ptBR } from '@mui/material/locale';
import { ptBR as pickersPtBR } from '@mui/x-date-pickers/locales';
import { ptBR as corePtBR} from '@mui/material/locale';

export const appTheme = createTheme(
    {
        palette: {
            mode: "light",
            primary: {
                main: "#0C3048",
            },
            secondary: {
                main: "#EFAF41",
            },
        },
    },
    ptBR,
    pickersPtBR,
    corePtBR
    );