import {Box, Button, Card, CardContent, Divider, FormControl, Grid, Skeleton, Typography} from "@mui/material";


export default function FichaTecnicaFormSkeleton() {
    return (
        <Box p={2}>
            <form>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <FormControl fullWidth>
                            <Skeleton variant="rectangular" height={40} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <FormControl fullWidth size="small">
                            <Skeleton variant="rectangular" height={40} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card variant="outlined">
                            <CardContent>
                                <Skeleton variant="text" width={100} />
                                <FormControl fullWidth>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <Skeleton variant="rectangular" height={40} />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Skeleton variant="rectangular" height={40} />
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h5">
                            <Skeleton variant="text" width={150} />
                        </Typography>
                        <Box mt={2}>
                            <Skeleton variant="rectangular" height={40} />
                        </Box>
                        <Skeleton variant="rectangular" height={36} width={120} sx={{ mt: 4 }} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h5">
                            <Skeleton variant="text" width={150} />
                        </Typography>
                        <Box mt={2}>
                            <Skeleton variant="rectangular" height={40} />
                        </Box>
                        <Skeleton variant="rectangular" height={36} width={120} sx={{ mt: 4 }} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} md={12} mb={5}>
                        <Box display="flex" justifyContent={"space-between"}>
                            <Skeleton variant="rectangular" height={36} width={100} />
                            <Skeleton variant="rectangular" height={36} width={100} />
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
}