import {apiSlice, parseQueryParams} from "../api/apiSlice";
import {ResultList} from "../../types/ResultList";
import {QueryParams} from "../../types/QueryParams";
import {Embalagem} from "../../types/Embalagem";

const endpointUrl: string = "/embalagens";

function listEmbalagensUrl({ lastEvaluatedKey = "", perPage = 10, search = "" }) {
    const params = { lastEvaluatedKey, perPage, search};
    return `${endpointUrl}?${parseQueryParams(params)}`;
}

function createEmbalagemMutation(embalagem: Embalagem) {
    return { url: endpointUrl, method: "POST", body: embalagem };
}

function updateEmbalagemMutation(embalagem: Embalagem) {
    return {
        url: `${endpointUrl}/${embalagem.id}`,
        method: "PUT",
        body: embalagem,
    };
}

function deleteEmbalagemMutation(id: string) {
    return {
        url: `${endpointUrl}/${id}`,
        method: "DELETE"
    };
}

export const embalagemApiSlice = apiSlice.injectEndpoints({
    endpoints: ({query, mutation}) => ({
        getEmbalagens: query<ResultList<Embalagem>, QueryParams>({
            query: listEmbalagensUrl,
            providesTags: ["Embalagem"]
        }),
        getEmbalagem: query<Embalagem, { id : string }>({
            query: ({id}) => `${endpointUrl}/${id}`,
            providesTags: ["Embalagem"]
        }),
        createEmbalagem: mutation<void, Embalagem>({
            query: createEmbalagemMutation,
            invalidatesTags: ["Embalagem"],
        }),
        updateEmbalagem: mutation<void, Embalagem>({
            query: updateEmbalagemMutation,
            invalidatesTags: ["Embalagem"],
        }),
        deleteEmbalagem: mutation<void, string>({
            query: deleteEmbalagemMutation,
            invalidatesTags: ["Embalagem"],
        }),
    }),
});

export const {
    useGetEmbalagensQuery,
    useGetEmbalagemQuery,
    useCreateEmbalagemMutation,
    useUpdateEmbalagemMutation,
    useDeleteEmbalagemMutation
} = embalagemApiSlice