enum UnidadeComercial {
    UNIDADE = "Unidade",
    GRAMA = "Grama",
    MILILITRO = "Mililitro",
    CENTIMETRO = "Centímetro",
    OUTROS = "Outros"
}

export const getUnidadeComercial = (key: keyof typeof UnidadeComercial) => {
    return UnidadeComercial[key as keyof typeof UnidadeComercial];
}

export const unidadesComerciais = Object.entries(UnidadeComercial).map(([option, label]) => ({ label, option }));

export const findUnidadeComercial = (option: string | undefined) => {
    return unidadesComerciais.find(unidadeComercial => unidadeComercial.option === option);
}