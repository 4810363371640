import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {QueryParams} from "../../types/QueryParams";

const url = process.env.REACT_APP_API_URL || "http://localhost:8080";

const baseUrl = `${url}`;

export const apiSlice = createApi({
   reducerPath: "api",
   tagTypes: ["Clientes", "Insumos", "FichaTecnica", "Embalagem"],
    endpoints: (builder) => ({}),
    baseQuery: fetchBaseQuery({baseUrl}),
});

export function parseQueryParams(params: QueryParams) {
    const query = new URLSearchParams();

    if (params.lastEvaluatedKey) {
        query.append("last_evaluated_key", params.lastEvaluatedKey.toString());
    }

    if (params.perPage) {
        query.append("per_page", params.perPage.toString());
    }

    if (params.search) {
        query.append("search", params.search);
    }

    return query.toString();
}
