import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import {clientesApiSlice} from '../features/clientes/clienteSlice';
import { apiSlice } from '../features/api/apiSlice'

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [clientesApiSlice.reducerPath]: clientesApiSlice.reducer,
  },

  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
      .concat(apiSlice.middleware)
      .concat(clientesApiSlice.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
