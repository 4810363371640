import {Box, Button, Typography} from '@mui/material'
import React, {useState} from 'react'
import {useGetClientesQuery} from './clienteSlice'
import {Link} from 'react-router-dom';
import ClienteTable from "./components/ClienteTable";
import {GridFilterModel} from "@mui/x-data-grid";

export default function ListClientes() {
    const [options, setOptions] = useState({
        page: 0,
        search: "",
        perPage: 10,
    });

    const {data, isFetching, error} = useGetClientesQuery(options);

    function handleOnPageChange(page: number) {
        setOptions({ ...options, page: page + 1 });
    }

    function handleOnPageSizeChange(perPage: number) {
        setOptions({ ...options, perPage });
    }

    function handleOnFilterChange(filterModel: GridFilterModel) {
        if (!filterModel.quickFilterValues?.length) {
            return setOptions({ ...options, search: "" });
        }

        const search = filterModel.quickFilterValues.join("");
        setOptions({ ...options, search });
    }

    return (
        <Box maxWidth="lg" sx={{mt: 4, mb: 4}}>

            <Typography variant='h3' component='h1'>
                Clientes
                <Box display="flex" justifyContent="flex-end">
                    <Button
                        variant='contained'
                        color='secondary'
                        style={{marginBottom: "1rem", marginTop: "1rem" }}
                        component={Link}
                        to="/clientes/create"
                    >
                        Novo cliente
                    </Button>
                </Box>
            </Typography>
            <ClienteTable
                data={data}
                perPage={10}
                isFetching={isFetching}
                handleOnPageChange={handleOnPageChange}
                handleFilterChange={handleOnFilterChange}
                handleOnPageSizeChange={handleOnPageSizeChange}
                />
            <Box display="flex" justifyContent="flex-start">
                <Button
                    variant='contained'
                    color='primary'
                    style={{marginTop: "1rem"}}
                    component={Link}
                    to="/"
                >
                    Voltar
                </Button>
            </Box>
        </Box>
    )
}
