import {Box, Paper, Typography} from '@mui/material';
import 'dayjs/locale/pt-br';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {enqueueSnackbar} from "notistack";
import {useGetInsumoQuery, useUpdateInsumoMutation} from "./insumoSlice";
import {InsumoRequest} from "../../types/Insumo";
import InsumoForm from "./components/InsumoForm";

export default function EditInsumo() {
    const id = useParams().id ?? "";
    const navigate = useNavigate();
    const {data: insumo, isFetching} = useGetInsumoQuery({id});

    const [updateInsumo, status] = useUpdateInsumoMutation();

    const [insumoState, setInsumoState] = useState<InsumoRequest>(
        {
            id: "",
            descricao: "",
            quantidade: {},
            custo: 0
        }
    );

    const [isDisabled, setIsDisabled] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;

        if(name === 'quantidade') {
            setInsumoState(prevState => ({
                ...prevState,
                quantidade: {
                    ...prevState.quantidade,
                    valor: +value
                }
            }));
        } else {
            setInsumoState(insumoState => ({...insumoState, [name]: value}));
        }

    };

    const handleChangeOnEnum = (event: any, newValue: { label: string, option: string } | null) => {
        if (!newValue) {
            return;
        }

        setInsumoState(prevState => ({
            ...prevState,
            quantidade: {
                ...prevState.quantidade,
                unidade: newValue.option
            }
        }));
    };

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        await updateInsumo(insumoState);
    }


    useEffect(() => {
        if (insumo) {
            setInsumoState(insumo);
        }
    }, [insumo]);

    useEffect(() => {
        if (status.isSuccess) {
            enqueueSnackbar("Insumo atualizado com successo", {variant: "success"});
            setIsDisabled(true);
            navigate(-1);
        }
        if (status.isError) {
            console.log(status)
            enqueueSnackbar("Não foi possível atualizar o insumo", {variant: "error"});
        }
    }, [enqueueSnackbar, status.isError, status.isSuccess]);


    return (
        <Box>
            <Paper>
                <Box p={2}>
                    <Box mb={2}>
                        <Typography variant='h4'>Editar insumo</Typography>
                    </Box>
                </Box>

                <InsumoForm
                    insumo={insumoState}
                    isUpdating={status.isLoading}
                    isLoading={isFetching}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    handleChangeOnEnum={handleChangeOnEnum}
                />
            </Paper>
        </Box>
    )
}
