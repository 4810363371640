import {apiSlice, parseQueryParams} from "../api/apiSlice";
import {ResultList} from "../../types/ResultList";
import {Cliente} from "../../types/Cliente";
import {QueryParams} from "../../types/QueryParams";

const endpointUrl: string = "/clientes/pessoa-fisica";


function listClientesUrl({ page = 0, perPage = 10, search = "" }) {
    const params = { page, perPage, search};
    return `${endpointUrl}?${parseQueryParams(params)}`;
}

function createClienteMutation(cliente: Cliente) {
    return { url: endpointUrl, method: "POST", body: cliente };
}

function updateclienteMutation(cliente: Cliente) {
    return {
        url: `${endpointUrl}/${cliente.id}`,
        method: "PUT",
        body: cliente,
    };
}

export const clientesApiSlice = apiSlice.injectEndpoints({
   endpoints: ({query, mutation}) => ({
       getClientes: query<ResultList<Cliente>, QueryParams>({
           query: listClientesUrl,
           providesTags: ["Clientes"]
       }),
       getCliente: query<Cliente, { id : string }>({
           query: ({id}) => `${endpointUrl}/${id}`,
           providesTags: ["Clientes"]
       }),
       createCliente: mutation<Cliente, Cliente>({
           query: createClienteMutation,
           invalidatesTags: ["Clientes"],
       }),
       updateCliente: mutation<Cliente, Cliente>({
           query: updateclienteMutation,
           invalidatesTags: ["Clientes"],
       }),
   }),
});

export const {
    useGetClientesQuery,
    useGetClienteQuery,
    useCreateClienteMutation,
    useUpdateClienteMutation
} = clientesApiSlice