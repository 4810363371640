import {Box, Button, FormControl, Grid, Skeleton} from "@mui/material";

export default function EmbalagemFormSkeleton() {

    return (
        <Box p={2}>
            <form>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <Skeleton variant="rectangular" height={40} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <Skeleton variant="rectangular" height={40} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <Skeleton variant="rectangular" height={40} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <Skeleton variant="rectangular" height={40} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} mb={5}>
                        <Box display="flex" justifyContent="space-between">
                            <Button variant="contained" disabled>
                                <Skeleton variant="text" width={60} />
                            </Button>
                            <Button variant="contained" disabled>
                                <Skeleton variant="text" width={60} />
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
}