// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB0lpkhltjFPSZKke7Z9fiJxT4QbAVSY-o",
    authDomain: "docenela.firebaseapp.com",
    databaseURL: "https://docenela-default-rtdb.firebaseio.com",
    projectId: "docenela",
    storageBucket: "docenela.appspot.com",
    messagingSenderId: "712259199923",
    appId: "1:712259199923:web:71c3860790ecc3fcd521c6",
    measurementId: "G-931HKJVHEZ"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app)
export { auth }