import React from 'react'
import ClienteCard from "../clientes/ClienteCard";
import {Box} from "@mui/material";
import FichaTecnicaCard from "../fichastecnicas/FichaTecnicaCard";
import InsumoCard from "../insumos/InsumoCard";
import EmbalagemCard from "../embalagens/EmbalagemCard";

export default function HomePage(){
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            flexWrap: 'wrap',
            '@media (max-width: 768px)': {
                flexDirection: 'column', // for mobile screens, display cards in a column
                flexWrap: 'wrap',
                justifyContent: 'space-between',
            },
        }}>

            <InsumoCard />
            <FichaTecnicaCard />
            <EmbalagemCard />
        </Box>
    );
}