import {Box, Button, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom';
import {GridFilterModel} from "@mui/x-data-grid";
import {useDeleteEmbalagemMutation, useGetEmbalagensQuery} from "./embalagemSlice";
import EmbalagemTable from "./components/EmbalagemTable";
import {useSnackbar} from "notistack";

export default function ListEmbalagem() {

    const [options, setOptions] = useState({
        page: 0,
        search: "",
        perPage: 10,
    });

    const {enqueueSnackbar} = useSnackbar();
    const {data, isFetching} = useGetEmbalagensQuery(options);
    const [deleteInsumo, { error: deleteError, isSuccess: deleteSuccess }] = useDeleteEmbalagemMutation();

    async function handleDeleteEmbalagem(id: string) {
        await deleteInsumo(id);
    }

    function handleOnPageChange(page: number) {
        setOptions({ ...options, page: page + 1 });
    }

    function handleOnPageSizeChange(perPage: number) {
        setOptions({ ...options, perPage });
    }

    function handleOnFilterChange(filterModel: GridFilterModel) {
        if (!filterModel.quickFilterValues?.length) {
            return setOptions({ ...options, search: "" });
        }

        const search = filterModel.quickFilterValues.join("");
        setOptions({ ...options, search });
    }

    useEffect(() => {
        if (deleteSuccess) {
            enqueueSnackbar(`Embalagem removida`, { variant: "success" });
        }
        if (deleteError) {
            enqueueSnackbar(`Embalagem não removida`, { variant: "error" });
        }
    }, [deleteSuccess, deleteError, enqueueSnackbar]);

    return (
        <Box maxWidth="lg" sx={{mt: 4, mb: 4}}>

            <Typography variant='h3' component='h1'>
                Embalagens
                <Box display="flex" justifyContent="flex-end">
                    <Button
                        variant='contained'
                        color='secondary'
                        style={{marginBottom: "1rem", marginTop: "1rem" }}
                        component={Link}
                        to="/embalagens/create"
                    >
                        Nova embalagem
                    </Button>
                </Box>
            </Typography>
            <EmbalagemTable
                data={data}
                perPage={10}
                isFetching={isFetching}
                handleOnPageChange={handleOnPageChange}
                handleFilterChange={handleOnFilterChange}
                handleOnPageSizeChange={handleOnPageSizeChange}
                handleDelete={handleDeleteEmbalagem}
            />
            <Box display="flex" justifyContent="flex-start">
                <Button
                    variant='contained'
                    color='primary'
                    style={{marginTop: "1rem"}}
                    component={Link}
                    to="/"
                >
                    Voltar
                </Button>
            </Box>
        </Box>
    )
}
