import {DataGrid, GridColDef, GridFilterModel, GridRenderCellParams, GridRowsProp, GridToolbar} from "@mui/x-data-grid";
import {Link} from "react-router-dom";
import {Box, IconButton} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import {ResultList} from "../../../types/ResultList";
import {InsumoResponse} from "../../../types/Insumo";
import DeleteIcon from "@mui/icons-material/Delete";

type Props = {
    data: ResultList<InsumoResponse> | undefined,
    perPage: number,
    isFetching: boolean,
    rowsPerPage?: number

    handleOnPageChange: (page: number) => void;
    handleFilterChange: (filterModel: GridFilterModel) => void;
    handleOnPageSizeChange: (perPage: number) => void;
    handleDelete: (id: string) => void;

}

export default function InsumoTable({
                                         data,
                                         perPage,
                                         isFetching,
                                         rowsPerPage,
                                         handleOnPageChange,
                                         handleFilterChange,
                                         handleOnPageSizeChange,
                                         handleDelete
                                     }: Props) {

    const rows: GridRowsProp = data ? data.items.map((insumo) => ({
        id: insumo.id,
        descricao: insumo.descricao,
        unidade: insumo.quantidade.unidade,
        valor: insumo.quantidade.valor,
        custo: insumo.custo,
    })) : [];

    const descricaoColumn: GridColDef = {
        field: 'descricao',
        headerName: 'Descrição',
        minWidth: 250
    }

    const quantidadeColumn: GridColDef = {
        field: 'valor',
        headerName: 'Quantidade',
        minWidth: 125
    }

    const unidadeComercialColumn: GridColDef = {
        field: 'unidade',
        headerName: 'Unidade',
        minWidth: 175
    }

    const custoColumn: GridColDef = {
        field: 'custo',
        headerName: 'Custo',
        valueFormatter: (params) => {
            return `R$ ${params.value}`;
        },
        minWidth: 250
    }

    const acoesColumn: GridColDef = {
        field: 'id',
        headerName: 'Ações',
        renderCell: renderActionsCell
    }

    const columnsDesktop: GridColDef[] = [descricaoColumn, quantidadeColumn, unidadeComercialColumn, custoColumn,  acoesColumn];

    function renderActionsCell(params: GridRenderCellParams) {
        return (
            <Box>

            <Link
                to={`/insumos/${params.id}/edit`}
            >
                <IconButton
                    color='secondary'
                    aria-label="edit"
                    size="small"
                >
                    <EditIcon />
                </IconButton>
            </Link>
            <IconButton
                color="error"
                onClick={() => handleDelete(params.value)}
                aria-label="delete"
                data-testid="delete-button"
            >
                <DeleteIcon />
            </IconButton>
            </Box>
        );
    }

    const componentProps = {
        toolbar: {
            showQuickFilter: true,
            quickFilterProps: {
                debounceMs: 500
            }
        },
    }

    const rowCount = data?.total ?? 0;


    return (
        <Box>
            <Box sx={{
                flexGrow: 1,
                display: 'flex',
                height: 350,
                width: '100%'
            }}>
                <DataGrid
                    columns={columnsDesktop}
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    disableColumnSelector={true}
                    disableDensitySelector={true}
                    disableRowSelectionOnClick={true}
                    filterMode="server"
                    initialState={{
                        pagination: {
                            paginationModel: {pageSize: perPage, page: 0},

                        }
                    }}
                    loading={isFetching}
                    pageSizeOptions={[10, 25, 50, 100]}
                    paginationMode="server"
                    rowCount={rowCount}
                    rows={rows}
                    slotProps={componentProps}
                    slots={{toolbar: GridToolbar}}
                />
            </Box>
        </Box>
    );


}