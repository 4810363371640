import React from "react";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CustomCard from "../../components/CustomCard";

export default function FichaTecnicaCard() {
    return (
        <CustomCard
            linkAction={'/fichas-tecnicas'}
            name={'Fichas Técnicas'}
            description={'Clique aqui para gerenciar as fichas técnicas'}
            IconComponent={MenuBookIcon}
        />
    );
}