import {Box, Paper, Typography} from '@mui/material';
import 'dayjs/locale/pt-br';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useGetClienteQuery, useUpdateClienteMutation} from './clienteSlice';
import ClienteForm from './components/ClienteForm';
import {Cliente} from "../../types/Cliente";
import {Dayjs} from "dayjs";
import {enqueueSnackbar} from "notistack";

export default function EditCliente() {
    const id = useParams().id ?? "";
    const navigate = useNavigate();
    const {data: cliente, isFetching} = useGetClienteQuery({id});

    const [updateCliente, status] = useUpdateClienteMutation();

    const [clienteState, setClienteState] = useState<Cliente>(
        {
            "id": "",
            "nome": "",
            "telefone": "",
            "email": "",
            "cpf": "",
            "data_nascimento": ""
        }
    );

    const [isDisabled, setIsDisabled] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setClienteState(clienteState => ({...clienteState, [name]: value}));
    };

    const handleDateChange = (date: Dayjs | null) => {
        if (date) {
            setClienteState(clienteState => ({...clienteState, data_nascimento: date.format('YYYY-MM-DD')}));
        }
    };


    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        await updateCliente(clienteState);
    }

    useEffect(() => {
        if (cliente) {
            setClienteState(cliente);
        }
    }, [cliente]);

    useEffect(() => {
        if (status.isSuccess) {
            enqueueSnackbar("Cliente atualizado com successo", {variant: "success"});
            setIsDisabled(true);
            navigate(-1);
        }
        if (status.isError) {
            console.log(status)
            enqueueSnackbar("Não foi possível atualizar o cliente", {variant: "error"});
        }
    }, [enqueueSnackbar, status.isError, status.isSuccess]);

    return (
        <Box>
            <Paper>
                <Box p={2}>
                    <Box mb={2}>
                        <Typography variant='h4'>Editar cliente</Typography>
                    </Box>
                </Box>

                <ClienteForm
                    cliente={clienteState}
                    isDisabled={isDisabled}
                    isLoading={false}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    handleDateChange={handleDateChange}
                />
            </Paper>
        </Box>
    )
}
