import React from "react";

import CustomCard from "../../components/CustomCard";
import { ReactComponent as EmbalagemIcon } from './cardboard-icon.svg';

export default function EmbalagemCard() {

    return (
        <CustomCard
            linkAction={'/embalagens'}
            name={'Embalagens'}
            description={'Clique aqui para gerenciar as embalagens'}
            IconComponent={EmbalagemIcon}
        />
    );
}