import {Autocomplete, Box, Button, CircularProgress, FormControl, Grid, InputAdornment, TextField} from '@mui/material';
import React from 'react';
import {Link} from 'react-router-dom';
import {PriceFormatCustom} from "../../../components/PriceFormatCustom";
import {findUnidadeComercial, unidadesComerciais} from "../../../types/UnidadeComercial";
import EmbalagemFormSkeleton from "./EmbalagemFormSkeleton";
import {Embalagem} from "../../../types/Embalagem";
import {green} from "@mui/material/colors";

type Props = {
    embalagem: Embalagem,
    isUpdating?: boolean,
    isLoading?: boolean,
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    handleChange: (e: React.ChangeEvent<HTMLInputElement> ) => void;
    handleChangeOnEnum: (event: any, newValue: { label: string, option: string } | null) => void;
}

export default function EmbalagemForm({
                                        embalagem,
                                        isUpdating = false,
                                        isLoading = false,
                                        handleSubmit,
                                        handleChange,
                                       handleChangeOnEnum
                                    }: Props) {
    if(isLoading) {
        return <EmbalagemFormSkeleton />
    } else {
        return (
            <Box p={2}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <TextField required
                                           name="descricao"
                                           label="Descrição"
                                           value={embalagem?.descricao}
                                           onChange={handleChange}
                                           size="small"
                                           inputProps={{ maxLength: 255 }}
                                >
                                </TextField>

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <TextField required
                                           name="quantidade"
                                           label="Quantidade"
                                           size="small"
                                           value={embalagem?.quantidade.valor}
                                           onChange={handleChange}
                                           inputProps={{inputMode:"numeric", pattern:"[0-9]*", type: "number"}}
                                >
                                </TextField>

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth size="small">
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    size="small"
                                    options={unidadesComerciais}
                                    value={findUnidadeComercial(embalagem?.quantidade.unidade) || null }
                                    onChange={handleChangeOnEnum}
                                    renderInput={(params) => <TextField {...params} required label="Unidade Comercial" />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <TextField
                                    required
                                    name="custo"
                                    label="Custo"
                                    value={embalagem?.custo}
                                    onChange={handleChange}
                                    size="small"
                                    InputProps={{
                                        inputComponent: PriceFormatCustom as any,
                                        startAdornment: <InputAdornment position="start">R$</InputAdornment>
                                    }}

                                >
                                </TextField>

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12} mb={5} >
                            <Box display="flex" justifyContent={"space-between"}>
                                <Button variant='contained' component={Link} to="/embalagens">
                                    Voltar
                                </Button>
                                <Box sx={{ m: 1, position: 'relative' }}>
                                    <Button variant='contained' type="submit" color='secondary'
                                            disabled={isUpdating}
                                    >
                                        Salvar
                                    </Button>
                                    {isUpdating && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: green[500],
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        )
    }
}
