import {Box, Card, CardActionArea, CardContent, Typography} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";

type Props = {
    linkAction: string,
    name: string,
    description: string,
    IconComponent: React.ElementType
}

export default function CustomCard({
                                       linkAction,
                                       name,
                                       description,
                                       IconComponent
                                   }: Props) {
    return (
        <Card sx={{margin: '10px',  minWidth: '320px'}}>
            <CardActionArea component={Link} to={linkAction}>
                <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography gutterBottom variant="h5" component="div">
                            {name}
                        </Typography>
                        {IconComponent && <IconComponent />}
                    </Box>

                    <Box>
                        <Typography variant="body2" color="text.secondary">
                            {description}
                        </Typography>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}