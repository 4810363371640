import {Box, Paper, Typography} from '@mui/material';
import 'dayjs/locale/pt-br';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useSnackbar} from "notistack";
import {useGetEmbalagemQuery, useUpdateEmbalagemMutation} from "./embalagemSlice";
import EmbalagemForm from "./components/EmbalagemForm";
import {Embalagem} from "../../types/Embalagem";

export default function EditEmbalagem() {
    const id = useParams().id ?? "";
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const {data: embalagem, isFetching} = useGetEmbalagemQuery({id});

    const [updateEmbalagem, status] = useUpdateEmbalagemMutation();

    const [embalagemState, setEmbalagemState] = useState<Embalagem>(
        {
            id: "",
            descricao: "",
            quantidade: {},
            custo: 0
        }
    );

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;

        if(name === 'quantidade') {
            setEmbalagemState(prevState => ({
                ...prevState,
                quantidade: {
                    ...prevState.quantidade,
                    valor: +value
                }
            }));
        } else {
            setEmbalagemState(embalagemState => ({...embalagemState, [name]: value}));
        }

    };

    const handleChangeOnEnum = (event: any, newValue: { label: string, option: string } | null) => {
        if (!newValue) {
            return;
        }

        setEmbalagemState(prevState => ({
            ...prevState,
            quantidade: {
                ...prevState.quantidade,
                unidade: newValue.option
            }
        }));
    };

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        await updateEmbalagem(embalagemState);
    }


    useEffect(() => {
        if (embalagem) {
            setEmbalagemState(embalagem);
        }
    }, [embalagem]);

    useEffect(() => {
        if (status.isSuccess) {
            enqueueSnackbar("Embalagem atualizada com successo", {variant: "success"});
            navigate(-1);
        }
        if (status.isError) {
            enqueueSnackbar("Não foi possível atualizar a embalagem", {variant: "error"});
        }
    }, [enqueueSnackbar, status.isError, status.isSuccess, navigate]);


    return (
        <Box>
            <Paper>
                <Box p={2}>
                    <Box mb={2}>
                        <Typography variant='h4'>Editar embalagem</Typography>
                    </Box>
                </Box>

                <EmbalagemForm
                    embalagem={embalagemState}
                    isUpdating={status.isLoading}
                    isLoading={isFetching}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    handleChangeOnEnum={handleChangeOnEnum}
                />
            </Paper>
        </Box>
    )
}
