import {Box, Paper, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import {InsumoRequest} from "../../types/Insumo";
import {useCreateInsumoMutation} from "./insumoSlice";
import InsumoForm from "./components/InsumoForm";

export default function CreateInsumo() {
    const navigate = useNavigate();

    const [isDisabled, setIsDisabled] = useState(false);

    const [createInsumo, status] = useCreateInsumoMutation();

    const [insumoState, setInsumoState] = useState<InsumoRequest>({
        id: "",
        descricao: "",
        quantidade: {},
        custo: 0
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;

        if(name === 'quantidade') {
            setInsumoState(prevState => ({
                ...prevState,
                quantidade: {
                    ...prevState.quantidade,
                    valor: +value
                }
            }));
        } else {
            setInsumoState(insumoState => ({...insumoState, [name]: value}));
        }

    };

    const handleChangeOnEnum = (event: any, newValue: { label: string, option: string } | null) => {
        if (!newValue) {
            return;
        }
        setInsumoState(prevState => ({
            ...prevState,
            quantidade: {
                ...prevState.quantidade,
                unidade: newValue.option
            }
        }));
    };

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        await createInsumo(insumoState);
    }

    useEffect(() => {
        if (status.isSuccess) {
            enqueueSnackbar("Insumo cadastrado com successo", {variant: "success"});
            setIsDisabled(true);
            navigate(-1);
        }
        if (status.isError) {
            enqueueSnackbar("Não foi possível cadastrar o insumo", {variant: "error"});
        }
    }, [enqueueSnackbar, status.isError, status.isSuccess]);

    return (
        <Box>
            <Paper>
                <Box p={2}>
                    <Box mb={2}>
                        <Typography variant='h4'>Novo insumo</Typography>
                    </Box>
                </Box>

                <InsumoForm
                    insumo={insumoState}
                    isUpdating={status.isLoading}
                    isLoading={false}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    handleChangeOnEnum={handleChangeOnEnum}
                />
            </Paper>
        </Box>
    )
}
