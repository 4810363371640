import {Navigate, Outlet} from "react-router-dom";
import { useAuthState } from 'react-firebase-hooks/auth';
import {auth} from "../config/firebase-config";
import {CircularProgress} from "@mui/material";

export default function ProtectedRoute() {
    const [user, loading, error] = useAuthState(auth);

    if(loading) {
        return <CircularProgress />
    }

    return user ? <Outlet/> : <Navigate to="/login" replace={true}/>
}

