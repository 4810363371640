import {Box, Button, FormControl, Grid, TextField} from '@mui/material';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, {Dayjs} from 'dayjs';
import React from 'react';
import {Link} from 'react-router-dom';
import {Cliente} from "../../../types/Cliente";
import {TextFieldCPFMask} from "../../../components/TextFieldCPFMask";
import {TextFieldTelefoneMask} from "../../../components/TextFieldTelefoneMask";

type Props = {
    cliente: Cliente,
    isDisabled?: boolean,
    isLoading?: boolean,
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    handleChange: (e: React.ChangeEvent<HTMLInputElement> ) => void;
    handleDateChange: (date: Dayjs | null) => void;
}


export default function ClienteForm({
                                        cliente,
                                        isDisabled = false,
                                        isLoading = false,
                                        handleSubmit,
                                        handleChange,
                                        handleDateChange
                                    }: Props) {

    return (

        <Box p={2}>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <TextField required
                                       name="nome"
                                       label="Nome"
                                       value={cliente?.nome}
                                       onChange={handleChange}
                                       size="small"
                                       inputProps={{ maxLength: 255 }}
                            >
                            </TextField>

                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <TextField required
                                       name="telefone"
                                       label="Telefone"
                                       size="small"
                                       value={cliente?.telefone}
                                       onChange={handleChange}
                                       InputProps={{
                                               inputComponent: TextFieldTelefoneMask as any
                                       }}
                            >
                            </TextField>

                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <TextField
                                name="email"
                                label="E-mail"
                                value={cliente?.email}
                                onChange={handleChange}
                                size="small"
                                inputProps={{ maxLength: 255 }}
                            >
                            </TextField>

                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <TextField
                                name="cpf"
                                label="CPF"
                                value={cliente?.cpf}
                                onChange={handleChange}
                                size="small"
                                InputProps={{
                                    inputComponent: TextFieldCPFMask as any
                                }}
                            >
                            </TextField>

                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                                <DatePicker
                                    label="Data de nascimento"
                                    name="data_nascimento"
                                    value={dayjs(cliente?.data_nascimento)}
                                    onChange={handleDateChange}
                                    slotProps={{ textField: { size: 'small' } }}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} mb={5}>
                        <Box display="flex" justifyContent={"space-between"}>
                            <Button variant='contained' component={Link} to="/clientes">
                                Voltar
                            </Button>
                            <Button variant='contained' type="submit" color='secondary'
                                    disabled={isDisabled}
                            >
                                Salvar
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Box>
    )
}
